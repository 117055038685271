import React from 'react'

export default function Contact() {
  return (
    <div id="contact">
        
      <div id="title">
        <h1>Contact</h1>
        <div className="line"></div>
        </div>

        <p>Feel free to contact me for any project ideas or works!</p>

        <div className="button-wrapper">
          <a href="mailto:zhangzita@gmail.com" target="_blank"><button>Say Hello!</button></a>
        </div>

      </div>
  )
}
